import { oneApi } from 'config/api';
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas';

export default {
  props: {
    // 组件名称
    componentName: {
      default: 'Application',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件id
    moduleId: {
      default: '',
      type: String,
    },
    // 分校识别码
    locale: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 简介
    intro: {
      default: '',
      type: String,
    },
    // 按钮文案
    submit: {
      default: 'Submit',
      type: String,
    },
    /**
     * 表单字段名称配置
     */
    clue: {
      default: '',
      type: String,
    },
    host: {
      default: '',
      type: String,
    },
    secret: {
      default: '',
      type: String,
    },
    // 报名图片配置
    applicationWebImage: {
      default: '',
      type: String,
    },
    applicationTouchImage: {
      default: '',
      type: String,
    },
    // 留资简介
    resourceLeadIntro: {
      default: '',
      type: String,
    },
    // 留资提交按钮文案
    resourceLeadSubmit: {
      default: 'Submit',
      type: String,
    },
    isNew: {
      default: false,
      type: Boolean,
    },
    // 是否配置准考证
    configAdmitCard: {
      default: true,
      type: Boolean,
    },
    // 准考证获取入口文案
    admitEntryText: {
      default: 'EntryText',
      type: String,
    },
    // 准考证下载按钮
    admitDownloadText: {
      default: 'download',
      type: String,
    },
    // 准考证参数
    admitParams: {
      default: () => ([]),
      type: Array,
    },
    docketUrl: {
      default: '',
      type: String,
    },
    // 字段列表
    dataList: {
      default: () => ([]),
      type: Array,
    },
    registration: {
      default: () => ({}),
      type: Object,
    },
    // 配置来源类型
    type: {
      default: 'MODULE',
      type: String,
    },
    // 报名关联字段
    regisCourseRelated: {
      default: () => ([]),
      type: Array,
    },
  },
  data() {
    const config = this.getConfig()
    const {
      source_id, source_type, utm_medium, utm_source,
    } = this.$route.query;
    const currency = this.$store.state.currency.symbol;
    return {
      commonLoginData: false,
      source_id: source_id || '',
      source_type: source_type || '',
      utm_medium: utm_medium || '',
      utm_source: utm_source || '',
      currency,
      hasFirst: false,
      submitName: config.submitName,
      agreementNotice: config.agreementNotice,
      successNotice: config.successNotice,
      errorNotice: config.errorNotice,
      phoneError: config.phoneError,
      requiredError: config.requiredError,
      emailError: config.emailError,
      otpSend: config.otpSend,
      otp: config.otp,
      otpResend: config.otpResend,
      otpPlaceholder: config.otpPlaceholder,
      rules: config.rules,
      otpError: config.otpError,
      legalCheck: config.legalCheck,
      successOtp: config.successOtp,
      signUpCheckInfoDialog: config.signUpCheckInfoDialog,
      redeemCodeEntrance: config.redeemCodeEntrance,
      loadingTxt: config.loadingTxt,
      loading: false,
      studentsList: [],
      studentInfo: [],
      skuId: '',
      courseId: '',
      signUpStatus: 0,
      phoneCodeList: this.$store.state.phoneCodeList,
      phoneAreaCode: {},
      formData: {
        clue: '',
        host: '',
        secret: '',
        subStatus: true,
        zcStatus: true,
        emailShow: false,
        dataList: [],
      },
      djs: 0,
      djsSet: null,
      detailData: {},
      confirmDialogConfig: {
        show: false,
        title: '',
        content: '',
        mainBtnText: '',
        mainBtnClick: () => { },
        subBtnText: '',
        subBtnClick: () => { },
      },
      // 诊断弹窗信息
      evaluationDialogInfo: {
        title: '',
        message: '',
        confirmButtonText: '',
        showCancel: true,
        res: {},
      },
      showEvaluationDialog: false,
      courseDetail: {},
      // 订阅税
      subTaxPrice: 0,
      orderId: '',
      studentId: '',
      leadsSubmitId: '',
      isShowAppliDialog: false,
      isShowTicket: false,
      studentTicketData: [],
      activeStudentIndex: 0,
      customNoShowSelectList: [],
      currentNum: 1,
      studentBuyCourseInfo: {
        firstName: '',
        lastName: '',
        gradeName: '',
        examTime: '',
        resultPath: '',
        cancelUrl: '',
      },
      lastInsertedMap: new Map(),
      isPaySuccess: 0,
      totalPriceValue: 0,
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      examTimeList: [],
      pickerTimeList: [],
      appliConfigText: this.$t('page.application'),
      resultPath: '',
      cancelUrl: '',
      isMounted: false,
      isWebMounted: false,
      isTouchMounted: false,
    }
  },
  computed: {
    gradeList() {
      const gradeList = []
      this.dataList.forEach((e) => {
        if (e.key === 'gradeId') {
          e.classList.forEach((i) => {
            if (i.show) {
              gradeList.push(i)
            }
          })
        }
      })
      return gradeList
    },
    selectRelatedList() {
      return this.dataList.filter((item) => item.key === 'gradeId')[0].selectRelatedList
    },
    classList() {
      return this.dataList.filter((item) => item.key === 'gradeId')[0].classList
    },
    // resultPath() {
    //   return `/order/result/${this.orderId}?orderFrom=1&studentId=${this.studentId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`;
    // },
    // cancelUrl() {
    //   return `/order/detail/${this.orderId}?orderFrom=1&studentId=${this.studentId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`;
    // },
    activeStudentData() {
      return this.studentTicketData[this.activeStudentIndex]
    },
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
    webClient() {
      return process.env.clientType === 'web'
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    userInfo() {
      return this.$store.state.ucenter.user
    },
    isWebClient() {
      return process.env.clientType === 'web'
    },
    admitCardStudentData() {
      return this.studentTicketData.find((item) => item.cardNo === this.$store.state.ucenter.user.card)
    },
  },
  mounted() {
    if (this.isMounted) {
      return
    }
    this.isMounted = true

    if (process.client) {
      if (this.$route.query.isPaySuccess) {
        this.getTicket().then(() => {
          this.isPaySuccess = +this.$route.query.isPaySuccess
          this.orderId = +this.$route.query.orderId
          this.getSessionInfo()
          this.isShowAppliDialog = true;
          this.currentNum = 3;
          // 去掉当前url的isPaySuccess参数
          this.$nextTick(() => {
            const newQuery = { ...this.$route.query };
            delete newQuery.isPaySuccess;
            delete newQuery.orderId;
            if (newQuery.studentId) {
              delete newQuery.studentId;
            }

            this.$router.replace({ query: newQuery }).catch((err) => {
              console.log('路由替换错误:', err);
            });
          });
        })
      }
    }
  },
  methods: {
    async init() {
      const gradeList = this.$store.state.courses.gradeList || []
      // 初始化年级数据
      if (Array.isArray(gradeList) && gradeList.length === 0) {
        await this.$store.dispatch('courses/queryGradeList', { $axios: this.$axios })
      }
      // 初始化手机区号对象
      const initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0]
      this.phoneAreaCode = initPhoneAreaCode
      this.handleAreaCodeChange()
      if (this.isLogin && !this.$route.query.isPaySuccess) {
        this.getTicket()
      }
    },
    // 神策埋点-留资组件曝光上报
    trackReport(name) {
      this.Sensors.track(name, {
        cms_component_id: this.moduleId,
      })
    },
    async getTicket() {
      const res = await this.functionCatch(
        this.$axios.post(oneApi.queryTicket, {
          componentId: this.moduleId,
        }, { rewritePostBody: true }),
      );
      this.studentTicketData = res.data.list || []
      console.log('studentTicketData', this.studentTicketData);
    },
    initFormData() {
      const webClient = process.env.clientType === 'web'
      const dataList = []
      this.hasFirst = false
      this.dataList.forEach((item) => {
        const fieldData = {
          ...item,
          value: '',
          valueName: '', // touch端输入框回显已选择下拉列表数据
          checked: [], // touch端多选
          rules: [{
            required: !!item.required,
            message: this.requiredError,
            trigger: webClient ? 'blur' : 'onBlur',
          }],
          disabled: false,
        }
        if (item.optionsType === 'select' || item.optionsType === 'selectMore') {
          fieldData.showPopup = false
        }
        if (item.show) {
          if (item.key === 'email' && item.custom === false) {
            this.formData.emailShow = true
          }
          // if (item.key === 'nickName') {
          //   this.dataList.splice(index, 1, {
          //     ...item,
          //     value: this.$store.state.ucenter.user.nickName,
          //   });
          // }

          if (item.key === 'fullName') {
            this.hasFirst = true
            dataList.push({
              key: 'givenName',
              name: item.name[0],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              // disabled: !this.$store.state.ucenter.isNewUser && !!this.userInfo.firstName,
              value: this.$store.state.ucenter.isNewUser ? '' : this.userInfo.firstName,
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
            dataList.push({
              key: 'familyName',
              name: item.name[1],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              // disabled: !this.$store.state.ucenter.isNewUser && !!this.userInfo.lastName,
              value: this.$store.state.ucenter.isNewUser ? '' : this.userInfo.lastName,
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
          } else if (item.key === this.registration.account) {
            dataList.push(fieldData)
            // dataList.push({
            //   key: 'verifyCode',
            //   optionsType: item.optionsType,
            //   name: this.otp,
            //   index: item.index,
            //   show: item.show,
            //   custom: item.custom,
            //   required: item.required,
            //   disabled: false,
            //   value: '',
            //   type: item.key,
            //   rules: [{
            //     required: !!item.required,
            //     message: this.otpPlaceholder,
            //     trigger: webClient ? 'blur' : 'onBlur',
            //   }],
            // })
          } else {
            dataList.push(fieldData)
          }
        }
        if (!item.show && item.optionsType === 'select') {
          this.customNoShowSelectList.push(fieldData)
        }
      })
      const orderList = dataList.sort((a, b) => a.index - b.index)
      this.formData.dataList = orderList
      console.log('initFormData', this.formData.dataList);
    },

    async handleDownloadAdmit() {
      const dom = document.querySelector('#admitCard')
      const elementWidth = dom.offsetWidth;
      const elementHeight = dom.offsetHeight
      html2canvas(dom, {
        scale: 8,
        useCORS: true,
        width: elementWidth,
        height: elementHeight,
        logging: true,
        ignoreElements: (element) => {
          if (element.getAttribute('loading') === 'lazy') {
            return true;
          }
          return false;
        },
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        // const pdfWidth = 595.28; // A4 宽度
        // const pdfHeight = 841.89; // A4 高度
        const pdfWidth = 841.89; // A4 横向宽度
        const pdfHeight = 595.28; // A4 横向高度
        let imgWidth = pdfWidth; // 默认填满宽度
        let imgHeight = (elementHeight / elementWidth) * pdfWidth; // 按比例计算高度

        // **如果高度超出 A4，调整大小**
        if (imgHeight > pdfHeight) {
          imgHeight = pdfHeight;
          imgWidth = (elementWidth / elementHeight) * pdfHeight;
        }

        const pdf = new JsPDF({
          orientation: 'landscape', // **关键：设置横向**
          unit: 'px',
          format: [pdfWidth, pdfHeight],
        });

        const xOffset = (pdfWidth - imgWidth) / 2; // 居中对齐
        const yOffset = (pdfHeight - imgHeight) / 2;

        pdf.addImage(imgData, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);

        pdf.save('admit.pdf');
      }).catch((error) => {
        console.error('下载失败:', error);
        if (this.isWebClient) {
          this.$ElMessage.error('下载失败');
        } else {
          this.$Toast('下载失败');
        }
      });
    },
    getDataParamsList() {
      let dataParamsList = []
      try {
        this.formData.dataList.forEach((item) => {
          let value = ''
          if (item.optionsType === 'input') {
            if (item.key === 'phone' && item.custom === false) {
              value = item.value
              if (item.value !== '') {
                dataParamsList.push({
                  key: 'areaCode',
                  value: this.phoneAreaCode.value,
                  custom: false,
                })
              }
            } else {
              value = item.value
            }
          }
          if (item.optionsType === 'select') {
            if (item.value !== '') {
              value = item.options[item.value].name
            }
          }
          if (item.optionsType === 'selectMore') {
            // 判断多选无值时
            if (item.value !== '') {
              const tempList = []
              item.value.forEach((option) => {
                tempList.push(item.options[option].name)
              })
              const preValue = tempList.join(',')
              value = JSON.stringify(preValue.split(',')).replace(/"/g, '\"')
            }
          } else {
            dataParamsList.push({
              // 自定义字段key传name
              key: item.key || undefined,
              name: item.name,
              value,
              custom: item.custom,
              multi: item.optionsType === 'selectMore',
            })
          }
        })
      } catch (error) {
        console.error(error)
        dataParamsList = []
      }
      return dataParamsList
    },

    // 发送验证码
    async sendCode() {
      const webClient = process.env.clientType === 'web'
      if (this.djs === 0) {
        const arr = this.formData.dataList
        let data = {}
        let vaPass = false
        if (this.registration.account === 'phone') {
          data = {
            scene: 1,
            type: 0, // 0手机号
          }
          arr.forEach((e) => {
            if (e.key === 'phone') {
              data.contactInfo = e.value
              data.countryCallingCode = this.phoneAreaCode.value
            }
          })
          if (webClient) {
            vaPass = this.validateWeb('phone')
            let phoneNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'phone') {
                phoneNum = item.value
              }
            })
            if (phoneNum.match(this.phoneAreaCode.reg)) {
              vaPass = true
            }
          } else {
            vaPass = await this.validateTouch('phone')
            let phoneNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'phone') {
                phoneNum = item.value
              }
            })
            if (phoneNum.match(this.phoneAreaCode.reg)) {
              vaPass = true
            }
          }
        } else {
          data = {
            scene: 1,
            type: 2, // 2邮箱
          }
          arr.forEach((e) => {
            if (e.key === 'email') {
              data.contactInfo = e.value
            }
          })
          if (webClient) {
            vaPass = this.validateWeb('email')
            let emailNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'email') {
                emailNum = item.value
              }
            })
            if (emailNum.match(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)) {
              vaPass = true
            }
          } else {
            vaPass = await this.validateTouch('email')
            let emailNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'email') {
                emailNum = item.value
              }
            })
            if (emailNum.match(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)) {
              vaPass = true
            }
          }
        }
        if (!vaPass) return
        const res = await this.functionCatch(this.$axios.post(oneApi.sendLoginMobileCode, data, { rewritePostBody: true }));
        if (res.code === 0) {
          if (process.env.clientType === 'web') {
            this.$ElMessage({
              type: 'success',
              message: this.successOtp,
            })
          } else {
            this.$Notify({ type: 'success', message: this.successOtp })
          }
          this.djs = 60
          this.djsSet = setInterval(() => {
            if (this.djs === 0) {
              this.otpSend = this.otpResend
              clearInterval(this.djsSet)
              return
            }
            this.djs -= 1
          }, 1000);
        } else if (process.env.clientType === 'web') {
          this.$ElMessage({
            type: 'warning',
            message: res.msg,
          })
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
      }
    },

    validateWeb(type) {
      let name = ''
      let bool = false
      this.formData.dataList.forEach((e) => {
        if (e.key === type) {
          name = `dataList.${e.index}.value`
        }
      })
      this.$refs.formData.validateField(name, (valid) => {
        if (!valid) {
          bool = true;
        } else {
          bool = false
        }
      })
      return bool
    },
    async validateTouch(type) {
      let bool = false
      bool = await this.$refs.SubmitInfo.validate(type)
        .then((valid) => {
          if (!valid) {
            bool = true;
          } else {
            bool = false
          }
          return bool
        })
        .catch(() => false)
      return bool
    },

    async cancelOrder() {
      this.isShowAppliDialog = false
      this.removeModalNode()
      this.currentNum = 1
      const data = {
        orderId: this.orderId,
      };

      const res = await this.$axios.post(oneApi.cancelOrder, data, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        if (this.webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
      }
    },
    retryPay() {
      this.payOrder()
    },
    handleBack() {
      if (this.currentNum === 1) {
        this.isShowAppliDialog = false
        return;
      }
      this.currentNum -= 1;
    },

    removeModalNode() {
      const vModals = document.querySelectorAll('.v-modal');

      // 遍历 NodeList 并移除每个元素
      vModals.forEach((modal) => {
        modal.remove();
      });
      // 解除 body 的 overflow:hidden 限制
      const body = document.querySelector('body');
      body.classList.remove('el-popup-parent--hidden');
    },

    async isFreeCourse(type) {
      const webClient = process.env.clientType === 'web'
      let totalPriceValue = 0
      let needAdd = false

      const data = {
        skuId: this.skuId,
      };

      const res = await this.functionCatch(this.$axios.post(oneApi.queryGoodDetail, data, { rewritePostBody: true }));
      if (Number(res.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return;
      }
      const resData = res.data || {};
      this.courseDetail = Object.assign(this.courseDetail, resData);
      // 商品类型 1 = 课程；2 = 书籍
      const goodType = this.courseDetail.origin * 1 || 1;
      const isFirstGenerate = !resData.existStage
      const requiredAttachedItems = []
      const selectedAttachedItems = []
      if (isFirstGenerate) {
        const attachedItems = this.courseDetail.attachedItems || []
        attachedItems.forEach((attachedItem) => {
          if (attachedItem.required) {
            requiredAttachedItems.push(attachedItem)
          }

          if (!attachedItem.required && attachedItem.selected) {
            selectedAttachedItems.push(attachedItem)
          }
        })
      }
      if (goodType == 2) {
        if (this.$store.state.locale === 'hk' || this.$store.state.locale === 'tm') {
          needAdd = false
        } else {
          needAdd = this.courseDetail.needPostAdd !== 0
        }
      } else {
        const selectedAttachedItems2 = requiredAttachedItems.concat(selectedAttachedItems)
        needAdd = selectedAttachedItems2.findIndex((item) => item.needPostAdd) >= 0
      }
      const { makePriceIsInclude } = resData
      totalPriceValue = resData.sellPrice

      const country = await this.functionCatch(this.$axios.post(oneApi.queryCountry, {}, { rewritePostBody: true }))
      if (Number(country.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: country.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: country.msg })
        }
        return;
      }
      const countryList = country.data.list;
      const countryId = countryList[0].id;
      const goodsList = [{
        sellPrice: this.courseDetail.singleSellPrice,
        skuId: this.skuId,
        categoryId: this.courseDetail.goodsCategoryId,
      }]
      const data2 = {
        platformType: this.courseDetail.spec.platformType,
        countryId,
        categorys: goodsList,
      }
      const res2 = await this.functionCatch(this.$axios.post(oneApi.queryCategoryTax, data2, { rewritePostBody: true }))
      if (Number(res2.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res2.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res2.msg })
        }
        return;
      }
      const resData2 = res2.data || {}
      const originPriceList = resData2.list || [];

      if (makePriceIsInclude === 1) {
        totalPriceValue += originPriceList[0].tax
      }

      const data3 = {
        total: totalPriceValue,
        payMethod: 1,
      }
      const res3 = await this.functionCatch(this.$axios.post(oneApi.getHandlingFee, data3, { rewritePostBody: true }))
      if (Number(res3.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res3.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res3.msg })
        }
        return;
      }

      totalPriceValue += (res3.data.handlingFee || 0)
      console.log('totalPriceValue', totalPriceValue, needAdd)
      if (type === 'isGetPrice') {
        this.totalPriceValue = totalPriceValue
        this.currentNum += 1;
        this.loading = false;
        return
      }
      if (totalPriceValue === 0 && !needAdd) {
        await this.toPay()
      } else {
        // this.$router.push(`/order/create/${this.skuId}?leadsSubmitId=${this.leadsSubmitId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`)
        await this.toPay()
      }
    },

    async toPay() {
      const data = {
        automaticDeduction: 1,
        type: this.courseDetail.origin * 1 || 1,
        skuBOList: [
          {
            skuId: this.skuId,
            ignoreSkuIdList: [],
            num: 1,
          },
        ],
      }
      const res = await this.functionCatch(this.$axios.post(oneApi.createOrder, data, { rewritePostBody: true, leadsSubmitId: this.leadsSubmitId }))
      if (Number(res.code) !== 0) {
        this.loading = false
        if (this.webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return;
      }
      const resData = res.data || {};
      this.orderId = resData.orderId

      this.payOrder()
      // this.$router.push(this.resultPath);
    },
    async payOrder() {
      this.resultPath = `${window.location.pathname}?isPaySuccess=1&orderId=${this.orderId}`
      this.cancelUrl = `${window.location.pathname}?isPaySuccess=0&orderId=${this.orderId}`

      const resUid = await this.functionCatch(this.$axios.post(oneApi.getFamilyStudentsList, {}, { rewritePostBody: true }))
      if (Number(resUid.code) !== 0) {
        this.loading = false
        if (this.webClient) {
          this.$ElMessage({
            type: 'error',
            message: resUid.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: resUid.msg })
        }
        return;
      }
      const resDataUid = resUid.data || {}
      const studentId = resDataUid.currentAccount.uid
      this.studentId = studentId
      const api = oneApi.payOrder;
      const domain = window.location.origin;
      // 支付成功的回调页面
      const returnUrl = this.studentId ? `${domain}${this.resultPath}&studentId=${studentId}` : domain + this.resultPath;
      // 取消支付的回调页面
      const cancelUrl = this.studentId ? `${domain}${this.cancelUrl}&studentId=${studentId}` : domain + this.cancelUrl;
      const data2 = {
        orderId: this.orderId,
        returnUrl,
        cancelUrl,
        subscriptionsChannel: 32,
      }
      const res2 = await this.functionCatch(this.$axios.post(api, data2, { rewritePostBody: true }))
      if (Number(res2.code) !== 0) {
        this.loading = false
        if (this.webClient) {
          this.$ElMessage({
            type: 'error',
            message: res2.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res2.msg })
        }
        return;
      }
      console.log('res2', res2);

      const resData2 = res2.data || {};
      const payUrl = 'payUrl' in resData2 ? resData2.payUrl : '';

      if ('needPay' in resData2 && `${resData2.needPay}` === '1') {
        // 付费订单
        this.paidClassHandler(payUrl);
      } else if ('needPay' in resData2 && `${resData2.needPay}` === '0') {
        // 免费订单
        this.freeClassHandler();
      }
    },
    async postSignUpRecord() {
      const params = {
        componentId: this.moduleId,
        gradeId: this.studentBuyCourseInfo.gradeId,
        examTime: this.examTimeList.length === 1 ? this.examTimeList[0].timeDesc : this.studentBuyCourseInfo.examTime,
        skuId: this.skuId,
        username: this.studentBuyCourseInfo.firstName + this.studentBuyCourseInfo.lastName,
      }
      const res = await this.$axios.post(oneApi.signUpRecord, params, { rewritePostBody: true })
      console.log(res, 'postSignUpRecord');
    },
    getCourseId(arr) {
      // 1. 找到 gradeId 相关项
      const gradeItem = arr.find((item) => item.key === 'gradeId');
      if (!gradeItem) {
        console.log('No gradeId found');
        return null;
      }
      console.log(gradeItem, 'gradeItem');
      const gradeValue = this.webClient ? gradeItem.value : gradeItem.classList.find((item) => item.gradeName === gradeItem.value).value;
      const selectRelatedItems = gradeItem.selectRelatedList?.filter(
        (item) => item.gradeValue === gradeValue,
      );
      console.log(selectRelatedItems, 'selectRelatedItems');
      if (!selectRelatedItems || selectRelatedItems.length === 0) {
        console.log('No matching selectRelatedList item');
        return null;
      }

      const applicationIds = [];

      selectRelatedItems.forEach((selectRelatedItem) => {
        const associationItem = selectRelatedItem.associationList?.find(
          (item) => item.value === gradeValue,
        );
        if (!associationItem) {
          console.log('No matching associationList item');
          return;
        }
        // 2. 获取 gradeId 相关的匹配数据
        const gradeRelatedValue = associationItem?.gradeRelatedValue || [];
        const gradeRelatedValueSelected = associationItem.gradeRelatedValueSelected?.filter(Boolean) || [];

        // 3. 收集所有年级的 rowUuid
        const requiredRowUuids = gradeRelatedValue.flatMap((uuid) => {
          const customItem = arr.find((item) => item.custom && item.rowUuid === uuid);
          if (customItem && customItem.options) {
            const selectedOption = customItem.options[customItem.value]
            return selectedOption ? selectedOption.rowUuid : [];
          }
          return [];
        });
        let isValid = requiredRowUuids.length > 0
          && requiredRowUuids.every((uuid) => gradeRelatedValueSelected.includes(uuid));
        if (gradeRelatedValue.length === 0) {
          isValid = true;
        }
        // 4. 处理自定义字段部分（从 selectRelatedItem 取 customRelatedValueSelected）
        const filteredRegisCourseRelated = this.regisCourseRelated.filter((key) => key !== 'gradeId');
        if (filteredRegisCourseRelated.length > 0) {
          const customRelatedValues = [];
          // 遍历 regisCourseRelated 里所有的自定义字段 UUID
          const fieldUuidToSelectedOption = {};
          const customRelatedValueSelected = selectRelatedItem?.customRelatedValueSelected.filter((item) => item !== null) || [];

          customRelatedValueSelected.forEach((selectedOptionUuid) => {
            // 找到该 selectedOptionUuid 属于哪个 fieldUuid
            const foundField = selectRelatedItem.customList.find((f) => f.options.some((opt) => opt.rowUuid === selectedOptionUuid));

            if (foundField) {
              fieldUuidToSelectedOption[foundField.rowUuid] = selectedOptionUuid;
            }
          });

          console.log(fieldUuidToSelectedOption, '✅ fieldUuidToSelectedOption 映射关系');

          filteredRegisCourseRelated.forEach((fieldUuid) => {
            const selectedOptionUuid = fieldUuidToSelectedOption[fieldUuid];

            if (selectedOptionUuid) {
              // 存储自身选择结果
              customRelatedValues.push(selectedOptionUuid);
              console.log(selectedOptionUuid, '✅ selectedOptionUuid');

              // 获取当前字段的配置项
              const customItem = selectRelatedItem.customList.find((f) => f.rowUuid === fieldUuid);
              console.log(customItem?.options, '✅ customItem.options');

              // 在当前 customItem.options 里查找匹配的选项
              const optionConfig = customItem?.options.find((opt) => opt.rowUuid === selectedOptionUuid);
              console.log(optionConfig, '✅ optionConfig');

              if (optionConfig?.customRelatedValue) {
                optionConfig.customRelatedValue.forEach((relatedFieldUuid) => {
                  console.log(customItem.customOptionsValueSelected, '✅ customItem.customOptionsValueSelected');
                  console.log(relatedFieldUuid, '✅ relatedFieldUuid');

                  const selectedValue = customItem.customOptionsValueSelected[selectedOptionUuid]?.[relatedFieldUuid];
                  console.log(selectedValue, '✅ selectedValue');

                  if (selectedValue) {
                    customRelatedValues.push(selectedValue);
                  }
                });
              }
            }
          });

          // filteredRegisCourseRelated.forEach((fieldUuid, index) => {
          //   // 获取该字段在配置中的选中项UUID
          //   const customRelatedValueSelected = selectRelatedItem?.customRelatedValueSelected.filter((item) => item !== null)
          //   const selectedOptionUuid = customRelatedValueSelected[index];
          //   if (selectedOptionUuid) {
          //     // 存储自身选择结果
          //     customRelatedValues.push(selectedOptionUuid);
          //     console.log(selectedOptionUuid, 'selectedOptionUuid');
          //     console.log(selectRelatedItem.customList, 'selectRelatedItem.customList111');

          //     // 精确匹配选项层级的关联值
          //     const optionConfig = selectRelatedItem.customList
          //       .find((f) => f.rowUuid === fieldUuid)
          //       ?.options
          //       .find((opt) => opt.rowUuid === selectedOptionUuid);

          //     console.log(selectRelatedItem.customList
          //       .find((f) => f.rowUuid === fieldUuid)
          //       ?.options, 'selectRelatedItem.customList2222');

          //     console.log(optionConfig, 'optionConfig');
          //     const customItem = selectRelatedItem.customList
          //       .find((f) => f.rowUuid === fieldUuid);
          //     if (optionConfig?.customRelatedValue) {
          //       // 遍历该选项的所有关联字段配置
          //       optionConfig.customRelatedValue.forEach((relatedFieldUuid) => {
          //         // 获取该关联字段的实际选中值
          //         console.log(customItem.customOptionsValueSelected, 'customItem.customOptionsValueSelected');
          //         console.log(relatedFieldUuid, 'relatedFieldUuid');
          //         const selectedValue = customItem.customOptionsValueSelected[selectedOptionUuid]?.[relatedFieldUuid];
          //         console.log(selectedValue, 'selectedValue1111');
          //         if (selectedValue) {
          //           console.log(selectedValue, 'selectedValue2222');
          //           customRelatedValues.push(selectedValue);
          //         }
          //       });
          //     }
          //   }
          // });
          // 2. 收集C端表单的关联值
          const formValues = [];
          filteredRegisCourseRelated.forEach((fieldUuid) => {
            // 查找表单中对应的字段
            const formField = arr.find((item) => item.rowUuid === fieldUuid);
            console.log(formField, 'formField');
            formValues.push(formField?.options[formField.value]?.rowUuid);
            if (formField?.value !== undefined && formField?.value !== null) {
              // 获取该字段的选中选项
              const selectedOption = formField.options[formField.value];
              console.log(selectedOption, 'selectedOption');
              // 收集该选项的所有关联字段值
              selectedOption?.customRelatedValue?.forEach((relatedFieldUuid) => {
                const relatedFormField = arr.find((item) => item.rowUuid === relatedFieldUuid);
                if (relatedFormField?.value !== undefined && relatedFormField?.value !== null) {
                  formValues.push(relatedFormField?.options[relatedFormField.value]?.rowUuid);
                }
              });
            }
          });
          console.log('配置要求的值:', customRelatedValues);
          console.log('表单实际的值:', formValues);
          isValid = isValid && customRelatedValues.every((value) => formValues.includes(value)) && formValues.every((val) => customRelatedValues.includes(val));
        }
        if (isValid) {
          applicationIds.push(selectRelatedItem.applicationId);
        }
      });

      console.log(applicationIds, 'applicationIds');

      if (applicationIds.length > 0) {
        return applicationIds[0];
      }
      console.log('Conditions not met for courseId');
      return null;
    },

    /**
     * 免费班情况
     * 直接跳转至报名成功页
     */
    freeClassHandler() {
      this.setSessionInfo()
      const path = `${window.location.origin}${this.resultPath}`
      window.location.href = path;
    },
    getSessionInfo() {
      const tempStudentBuyCourseInfo = window.sessionStorage.getItem('temp_studentBuyCourseInfo')
      const tempDetailData = window.sessionStorage.getItem('temp_detailData')
      const tempExamTimeList = window.sessionStorage.getItem('temp_examTimeList')
      const tempPlatformContent = window.sessionStorage.getItem('temp_platformContent')
      if (tempStudentBuyCourseInfo) {
        this.studentBuyCourseInfo = Object.assign(this.studentBuyCourseInfo, JSON.parse(tempStudentBuyCourseInfo))
        window.sessionStorage.removeItem('temp_studentBuyCourseInfo')
      }
      if (tempDetailData) {
        this.detailData = Object.assign(this.detailData, JSON.parse(tempDetailData))
        window.sessionStorage.removeItem('temp_detailData')
      }
      if (tempExamTimeList) {
        this.examTimeList = JSON.parse(tempExamTimeList)
        window.sessionStorage.removeItem('temp_examTimeList')
      }
      if (tempPlatformContent) {
        this.platformContent = Object.assign(this.platformContent, JSON.parse(tempPlatformContent))
        window.sessionStorage.removeItem('temp_platformContent')
      }
    },
    setSessionInfo() {
      try {
        window.sessionStorage.setItem('temp_studentBuyCourseInfo', JSON.stringify(this.studentBuyCourseInfo));
        window.sessionStorage.setItem('temp_detailData', JSON.stringify({
          id: this.detailData.id,
          title: this.detailData.title,
          spec: this.detailData?.spec,
          online: this.detailData.online,
          platformContent: this.detailData?.platformContent,
        }));

        if (this.examTimeList) {
          window.sessionStorage.setItem('temp_examTimeList', JSON.stringify(this.examTimeList));
        }
        if (this.platformContent) {
          window.sessionStorage.setItem('temp_platformContent', JSON.stringify(this.platformContent));
        }

        // 读取数据检查
        console.log('sessionStorage 写入检查:', {
          studentBuyCourseInfo: window.sessionStorage.getItem('temp_studentBuyCourseInfo'),
          detailData: window.sessionStorage.getItem('temp_detailData'),
        });
      } catch (error) {
        console.error('sessionStorage 写入失败:', error);
      }
    },
    /**
     * 付费班情况
     * 调用支付接口，获取支付链接
     * 跳转至支付页面
     */
    paidClassHandler(payUrl) {
      // 付费订单
      if (payUrl === '') {
        this.$ElMessage.error('The payment link is empty');
        return;
      }
      this.payUrl = payUrl;
      this.setSessionInfo()
      // 当前页面跳转至支付页面
      setTimeout(() => {
        window.location.href = this.payUrl;
      }, 100);
    },

    async functionCatch(fn) {
      try {
        const res = await fn
        return res
      } catch (error) {
        this.loading = false
        return {
          code: 999,
          msg: error,
        }
      }
    },

    /**
     * 获取文案配置
     */
    getConfig() {
      const { locale } = this.$store.state
      let requiredError = 'The input is required.'
      let emailError = 'The Email address seems invalid.'
      let phoneError = 'The Phone Number seems invalid.'
      let submitName = 'Submit'
      let agreementNotice = 'Sign up for our mailing list to receive the latest news, promotions and updates!'
      let successNotice = 'Thank you for your interest in Think Academy!'
      let successOtp = 'OTP has been sent'
      let errorNotice = 'Submission failed. Please try again later.'
      let otp = 'OTP'
      let otpPlaceholder = 'Enter OTP'
      let otpError = 'Incorrect OTP entered.'
      let otpSend = 'Send'
      let loadingTxt = 'The current number of registrants is too high please wait a moment.'
      let otpResend = 'Resend'
      let legalCheck = 'By continuing, you need to be the parents or legal guardian of the students and confirm that you agree to our <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and be contacted via text messages and emails.'
      let signUpCheckInfoDialog = {
        case1: {
          title: 'Please take the Evaluation',
          content1: 'Your child must pass the Evaluation before signing up to this course. ',
          content2: 'attempt remaining',
          mainBtnText: 'Start test',
        },
        case2: {
          unableSignUp: 'Unable to sign up',
          ok: 'OK',
          msg: '',
        },
        case3: {
          title: 'Reminder',
          mainBtnText: 'Confirm',
        },
        case1014: {
          title: 'Auto pay in process',
          content: 'Your account will be automatically debited within 24 hours. We will inform you via text message once the payment is competed.',
          mainBtnText: 'I got it',
        },
        case604: {
          title: 'Unable to sign up',
          content: 'Sorry, this course is only available for new users, and each user can only sign up once. Come and browse more courses!',
          mainBtnText: 'View courses',
          subBtnText: 'Cancel',
        },
      }
      let redeemCodeEntrance = ['If you have a Think Academy registration qualification code, please', 'redeem here']
      if (locale === 'hk') {
        loadingTxt = '當前報名人數較多，系統正在處理，請稍等片刻。'
        submitName = '提交'
        agreementNotice = '我想接收最新資訊，包括最新課程資料、學習資源、試題詳解等。'
        successNotice = '提交成功，感謝您對學而思的關註！'
        successOtp = '验证码已发送'
        errorNotice = '提交失敗，請稍後重試。'
        requiredError = '請輸入此必填項'
        emailError = '請輸入有效的郵箱地址'
        phoneError = '請輸入有效的手機號碼'
        otp = '驗證碼'
        otpPlaceholder = '輸入驗證碼'
        otpError = '輸入的驗證碼不正確。'
        otpSend = '發送'
        otpResend = '重發'
        legalCheck = '我已閱讀並同意<a class="link-text" href="/terms-of-use" target="_blank">用戶協議</a>、<a class="link-text" href="/privacy-policy" target="_blank">隱私協議</a>、<a class="link-text" href="/child-privacy" target="_blank">兒童隱私協議</a>'
        signUpCheckInfoDialog = {
          case1: {
            title: '請參加入學評估',
            content1: '您的孩子在報名參加本課程之前必須通過入學評估。剩餘',
            content2: '次嘗試',
            mainBtnText: '確定',
          },
          case2: {
            unableSignUp: '無法報名',
            ok: '確定',
            msg: '您的孩子沒有通過入學評估。我們建議報名參加的課程為：高級級別。',
          },
          case3: {
            title: '提醒',
            mainBtnText: '確定',
          },
          case1014: {
            title: '自動扣款中',
            content: '此班級正在自動扣款中，您的帳戶將在24小時內完成自動扣款，扣款成功後，您將收到簡訊通知。',
            mainBtnText: '知道了',
          },
          case604: {
            title: '無法註冊',
            content: '抱歉，此課程只對新用戶開放，每個用戶只能註冊一次。 快來瀏覽更多課程吧！',
            mainBtnText: '查看課程',
            subBtnText: '取消',
          },
        }
        redeemCodeEntrance = ['如果您有學而思報名資格碼，請在', '這里兌換']
      }
      if (locale === 'tmc') {
        successNotice = 'Thank you for your interest in Mathink Club!'
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Mathink Club\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'tm') {
        successNotice = 'Thank you for your interest in Think Matrix!'
      }
      if (locale === 'uk') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'sg' || locale === 'bmsg') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a id="link-sg-terms-use" class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'ca') {
        legalCheck = 'By continuing,you need to be the parents or legal guardian of the students to agree to Think Academy’s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a>.'
      }
      return {
        // 提交按钮名称
        submitName,
        // 协议提示
        agreementNotice,
        // 提交成功提示
        successNotice,
        // 提交失败提示
        errorNotice,
        // 手机号错误提示
        phoneError,
        // 输入错误提示
        requiredError,
        // 邮箱号错误提示
        emailError,
        otp,
        otpPlaceholder,
        otpError,
        otpSend,
        otpResend,
        legalCheck,
        signUpCheckInfoDialog,
        redeemCodeEntrance,
        successOtp,
        loadingTxt,
      }
    },
  },
}
