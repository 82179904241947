<template>
  <div class="wrapper">
    <div id="admitCard" class="admit-card">
      <div class="title">
        {{ admitCardData.className }}
      </div>
      <div class="info-card">
        <div v-if="admitCardData.studentName" class="info-row">
          <div class="info-label">
            {{ appliConfigText.name }}
          </div>
          <div class="info-value">
            {{ admitCardData.studentName }}
          </div>
        </div>
        <div class="info-row">
          <div class="info-label">
            {{ appliConfigText.grade }}
          </div>
          <div class="info-value">
            {{ admitCardData.gradeName }}
          </div>
        </div>
        <div class="info-row">
          <div class="info-label">
            {{ appliConfigText.id }}
          </div>
          <div class="info-value">
            {{ admitCardData.cardNo }}
          </div>
        </div>
        <div class="info-row">
          <div class="info-label">
            {{ appliConfigText.time }}
          </div>
          <div class="info-value">
            {{ admitCardData.examTime }}
          </div>
        </div>
        <div class="info-row">
          <div class="info-label">
            {{ appliConfigText.place }}
          </div>
          <div v-if="admitCardData.classroomAddress" class="info-value">
            {{ admitCardData.classroomAddress }}
          </div>
          <div v-else class="info-value">
            {{ appliConfigText.online }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdmitCard',
  props: {
    admitCardData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      appliConfigText: this.$t('page.application'),
    }
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  position: fixed;
  left: 99999px;
  top: 99999px;
}
.admit-card {
  background: #f4f6fa;
  width: 740px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  .title {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
  }
  .info-card {
    width: 700px;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;
    .info-row {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #e8e8e8;
      height: 60px;

      &:last-child {
        border-bottom: none;
      }

      .info-label {
        width: 175px;
        font-weight: bold;
        padding: 5px 10px;
        border-right: 1px solid #e8e8e8;
        text-align: center;
        padding: 18px 0;
      }

      .info-value {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 5px 10px;
        word-break: break-word;
        padding: 0 20px;
        &:not(:last-child) {
          border-right: 1px solid #e8e8e8;
        }
      }
    }
  }
}
</style>
