import { oneApi, CustomerService } from 'config/api'
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf'
import { config, S3 } from 'aws-sdk'

export default {
  props: {
    moduleId: {
      default: -1,
      type: Number,
    },
    // 组件名称
    componentName: {
      default: 'ScoreQueryComponent',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    theme: {
      default: '',
      type: String,
    },
    themeIntro: {
      default: '',
      type: String,
    },
    layout: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {
      queryWhereConfig: this.layout.queryWhereConfig,
      queryResultConfig: this.layout.queryResultConfig,
      certConfig: this.layout.certConfig,
      shareConfig: this.layout.shareConfig,
      queryDataList: [],
      formData: {
        // 查询条件
        queryData: {},
        // 查询结果
        queryResult: [],
        subStatus: false,
      },
      resultData: {},
      certInfo: {},
      showContent: false,
      loading: false,
      certLoading: false,
      requiredError: this.$t('featureComponents.page.tips.invalid'),
      first: 1, // 姓名展示 value //本地下载
      second: 2, // 学校 or 排名展示 value //facebook
      third: 3, //  奖项展示 value //ins
      fourth: 4, //  奖项展示 value //whatsapp
    }
  },
  computed: {
    isWebClient() {
      return process.env.clientType === 'web'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.queryDataList = this.layout.queryWhereConfig.titles.map((item) => ({
        ...item,
        active: false,
        rules: [{
          required: true,
          message: this.requiredError,
          trigger: this.isWebClient ? 'blur' : 'onBlur',
        },
        {
          pattern: /^[a-zA-Z0-9]*$/,
          message: this.$t('page.scoreQuery.searchError'),
          trigger: this.isWebClient ? 'blur' : 'onBlur',
        }],
      }));
    },
    async certDownload() {
      this.certLoading = true;

      html2canvas(this.$refs.certBody, {
        scale: 8,
        useCORS: true,
        width: this.$refs.certBody.offsetWidth,
        height: this.$refs.certBody.offsetHeight,
        logging: true,
        ignoreElements: (element) => {
          if (element.getAttribute('loading') === 'lazy') {
            return true;
          }
          return false;
        },
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        const pdf = new JsPDF({
          unit: 'px',
          format: [595.28, 841.89],
        });
        // pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 尺寸: 210mm x 297mm
        pdf.addImage(imgData, 'JPEG', 0, 0, 595.28, 841.89);
        pdf.save('certificate.pdf');
        this.certLoading = false;
        this.downloadCertTrack()
      }).catch((error) => {
        console.error('证书下载失败:', error);
        this.certLoading = false;
        if (this.isWebClient) {
          this.$ElMessage.error('证书下载失败');
        } else {
          this.$Toast('证书下载失败');
        }
      });
    },
    async getS3Token() {
      const params = {
        fileName: 'sharePoster.png',
        businessName: 'scoreQueryComponent',
      }
      const res = await this.$axios.post(oneApi.getS3Token, params, { rewritePostBody: true });
      return res.data;
    },
    checkSharePoster() {
      if (this.shareConfig.choose === 2 || !this.shareConfig.tempUrl) {
        return false
      }
      return true
    },
    // 上传base64图片到亚马逊
    createS3(cfg) {
      const setting = {
        accessKeyId: cfg.accessKey,
        secretAccessKey: cfg.accessSecret,
        sessionToken: cfg.sessionToken,
        useAccelerateEndpoint: true,
      }
      config.update(setting)
      config.region = cfg.region
      const s3 = new S3({
        apiVersion: '2006-03-01',
      })
      return s3
    },
    async s3Upload(s3Config, Bucket, Key, Body) {
      const s3 = this.createS3(s3Config)
      return new Promise((resolve, reject) => {
        s3.putObject(
          {
            Body, Bucket, Key, ContentEncoding: 'base64', ContentType: 'image/png',
          }, // Body: 文件内容，Bucket: 桶名（接口返回）, Key: 文件路径（接口返回）
          (err) => {
            if (err) {
              reject(err)
            } else {
              resolve(`${s3Config.url}/${Key}`)
            }
          },
        ).on('httpError', (err) => {
          if (err && Body.onError) {
            Body.onError()
            reject(err)
          }
        })
      })
    },

    async uploadToS3(buffer, filePath) {
      try {
        const params = {
          scene: 'homework', // 场景值
          filePaths: [`.${filePath.split('/')[1]}`],
          target: 's3',
        };
        const { data } = await this.$axios.post(CustomerService.getSTST, params, { rewritePostBody: true });
        return await this.s3Upload(data, data.bucketName, data.filePaths[0], buffer);
      } catch (error) {
        console.error('上传图片失败', buffer, error);
        throw error;
      }
    },

    extractText(input) {
      // 使用正则表达式匹配括号或空格前的内容
      const match = input.match(/^(.*?)[（ ]/);
      // 如果匹配成功，返回匹配的第一个捕获组，否则返回原始字符串
      return match ? match[1] : input;
    },
    // 埋点
    submitTrack() {
      this.Sensors.track('score_query')
    },
    downloadCertTrack() {
      this.Sensors.track('download_certificate')
    },
    shareImgTrack() {
      this.Sensors.track('share_image')
    },
    shareFaceBookTrack() {
      this.Sensors.track('share_facebook')
    },
    shareInsTrack() {
      this.Sensors.track('share_instagram')
    },
    shareWhatsAppTrack() {
      this.Sensors.track('share_whatsapp')
    },

  },
}
